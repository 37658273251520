import Button from '@/atoms/Button/Button'
import Link from '@/atoms/Link/Link'
import Layout from '@/cmp/templates/Layout/Layout'

export default function Custom404() {
  return (
    <Layout title="404 | Page Not Found" canonical="">
      <section
        className="max-width-desktop  |  flex flex-col lg:flex-row items-center justify-center gap-10  |  mx-auto px-5 py-20"
        style={{ minHeight: '60vh' }}
      >
        <div className="text-center lg:text-left">
          <h1 className="mb-10">
            <span className="h1 font-bold  |  block  |  mb-5">404</span>
            <span>
              Looks like you&apos;re lost in space. Want to go back Home?
            </span>
          </h1>
          <Link href="/" className="block">
            <Button>Go Back to Home</Button>
          </Link>
        </div>
        <img
          className="h-full"
          src="/assets/icons/404Icon.svg"
          alt="404 Icon"
        />
      </section>
    </Layout>
  )
}
